import React, { Component } from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import unescape from "lodash.unescape"

import Layout from "../components/layout"
import SEO from "../components/seo"

import style from "./post.module.css"

class Post extends Component {
  render() {
    const post = this.props.data.wordpressWpBlog

    return (
      <Layout isMargin>
        <SEO
          title={post.yoast_meta.yoast_wpseo_title}
          description={post.yoast_meta.yoast_wpseo_metadesc}
        />

        <Grid style={{ marginTop: 75 }}>
          <Row className={style.contentTitle}>
            <Col>
              <h1 className={style.postTitle}>{unescape(post.title)}</h1>
              <Row className={style.postMeta}>
                <Col>
                  <div className={style.date}>{post.date}</div>
                </Col>
                <Col>
                  <div className={style.byline}>by {post.author.name}</div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={style.content}>
            <Col xs>
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </Col>
          </Row>
        </Grid>
      </Layout>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressWpBlog(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      author {
        name
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
